import { useLocation } from '@reach/router'
import { PRODUCTS } from 'lib/products/constants'

export const isFuneralsPage = (
  selectedProduct: PRODUCTS | null | undefined
): boolean => {
  if (!selectedProduct) {
    return false
  }
  return [PRODUCTS.FUNERAL, PRODUCTS.FUNERAL_PLAN].includes(selectedProduct)
}

export const isWillsPage = (
  selectedProduct: PRODUCTS | null | undefined
): boolean => {
  if (!selectedProduct) {
    return false
  }
  return [PRODUCTS.WILLS, PRODUCTS.TELEPHONE_WILLS].includes(selectedProduct)
}

export const isGuidePage = (): boolean => {
  const location = useLocation()
  return (
    location.pathname.startsWith('/guide') ||
    location.pathname.startsWith('/articles') ||
    location.pathname.startsWith('/blog')
  )
}
